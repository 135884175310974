@font-face {
    font-family: 'Graphiklcg';
    src: url('https://assets.website-files.com/5e589514a37e6e4ce2eff96a/5e5e03eb2c687c7ffc22a9d1_GraphikLCG-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'material-icons';
    src: url('https://assets.website-files.com/5d4c791b313d9a187b5e9338/5d4c791b313d9a06775e934a_material-icons-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

.xx_set_line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.xsb_select_drop0 {
    position: absolute;
    left: 0%;
    top: 0px;
    right: 0%;
    bottom: auto;
    z-index: 10000;
    min-height: 44px;
    padding-top: 46px;
}

.xsb_select_drop0.xsb_select_drop0--top {
    top: auto;
    bottom: 0px;
    padding-top: 0px;
    padding-bottom: 47px;
}

.xsb_select_drop {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-height: 44px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px #b2c1cf, 0 4px 13px -2px rgba(59, 106, 160, 0.6);
    -webkit-transition: all 200ms cubic-bezier(.25, .1, .661, .983);
    transition: all 200ms cubic-bezier(.25, .1, .661, .983);
}

.xsb_select_drop_li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 44px;
    padding-right: 8px;
    padding-left: 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    line-height: 1.2em;
    font-weight: 400;
    cursor: pointer;
}

.xsb_select_drop_li:hover {
    background-color: rgba(150, 203, 255, 0.19);
    color: #000;
}

.xsb_select_drop_li.active {
    background-color: #157afb;
    color: #fff;
}

.xsb_but_seturl_placeholder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* padding-right: 16px; */
    padding-left: 8px;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    /* font-size: 13px; */
    line-height: 1em;
    font-weight: 400;
}

.xsb_but_seturl_placeholder.xsb_but_seturl_placeholder--bold {
    color: #157afb;
    font-size: 15px;
    font-weight: 700;
}

.xx_but_menu_icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 36px;
    height: 36px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-family: material-icons, sans-serif;
    font-size: 20px;
    text-transform: none;
}

.xx_but_menu_icon.icon--16 {
    width: 16px;
    height: 16px;
    margin-right: 0.4em;
    font-size: 13px;
}

.xx_but_menu_icon.xx_but_menu_icon--secondary {
    height: 30px;
    padding-top: 2px;
    opacity: 0.58;
    font-family: material-icons, sans-serif;
    color: #b2c1cf;
    font-size: 16px;
    text-transform: none;
}

.xx_but_menu_icon.xx_but_sett_icon--bad {
    color: #ffbf00;
}

.xx_but_menu_icon.xx_but_sett_icon--inline {
    width: auto;
    padding-right: 6px;
    color: #157afb;
}

.xx_but_menu_icon.xx_but_menu_icon--link {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.xsb_but_link_input {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 44px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px none #000;
    border-radius: 4px;
    background-color: rgba(207, 219, 233, 0.25);
    box-shadow: inset 0 0 0 2px rgba(197, 208, 219, 0);
    -webkit-transition: border-width 200ms ease, box-shadow 200ms ease, border-color 200ms ease, background-color 325ms ease;
    transition: border-width 200ms ease, box-shadow 200ms ease, border-color 200ms ease, background-color 325ms ease;
    font-weight: 700;
    text-transform: none;
}

.xsb_but_link_input:hover {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #157afb;
    color: #00244d;
}

.xsb_but_link_input:active {
    background-color: rgba(150, 203, 255, 0.19);
    box-shadow: inset 0 2px 0 2px #fff;
}

.xsb_but_link_input:focus {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #157afb;
    color: #00244d;
}

.xsb_but_link_input.xsb_but_link_input--inline {
    background-color: transparent;
}