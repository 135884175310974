.vjs-quality-selector {
  font-size: 1.6em !important;
  transition: 0.2s ease-in-out !important;
}

.vjs-quality-selector .vjs-menu-content {
  max-height: none !important;
}

.video-js .vjs-quality-selector .vjs-menu,
.video-js .vjs-quality-item {
  color: var(--vjs-primary);
  font-size: 0.9em;
  line-height: 1.7em;
  border-bottom: 1px solid black;
}
