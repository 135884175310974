h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.ico {
    font-family: Materialicons, sans-serif;
    font-size: 1em;
    text-transform: none;
}

.x_entry0 .x_entry_preview0 {
    opacity: 0;
    display: none;
}

.x_entry0:hover .x_entry_preview0 {
    opacity: 1;
    display: flex;
}

a,
a:hover {
    color: currentColor;
    text-decoration: none;
}

.m_collapsible0:hover .m0 {
    opacity: 1;
    z-index: 10;
    transform: translate(0px, 0px);
}

.m0_z {
    pointer-events: none;
}

.m_collapsible0:hover .m0_z {
    opacity: 1;
}

.m0_collapsed .m_text {
    display: none;
}

:root {
    --main-bg-color: rgb(244, 239, 219);
    --main-bg-lighter-color: rgb(250, 249, 242);
    --main-bg-darker-color: rgba(232, 227, 207, 1);
    --main-text-color: rgb(0, 0, 0);
    --main-text-lighter-color: rgb(106, 96, 96);
    --main-border-color: rgb(206, 188, 188);
    --main-accent-color: #06a19a;
    --main-accent-color-alpha: rgba(6, 161, 154, 0.14);
    --main-accent-hover-color: rgba(10, 192, 183, 1);
    --main-accent-text-color: rgb(255, 255, 255);
    --main-white-color: rgb(255, 255, 255);
    --main-border-radius: 1em;
    --main-border-radius-small: 4px;
}

@font-face {
    font-family: "Materialicons";
    src: url("https://assets.website-files.com/61785133118e574036320746/618c0b73ef2495f432e431dd_material-icons.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

.x_meta_title_num {
    border-color: currentColor;
}

.x_button {
    margin-right: 1em;
}

.x_button:nth-last-of-type(1) {
    margin: 0em;
}

.l_filter_section:nth-last-child() {
    padding-right: 0em;
}

.x_button_container_right .x_button {
    margin-left: 1em;
}

.x_button_icon:only-child {
    margin: 0;
}

.x_meta_tag {
    margin-right: 1em;
}

.x_button_icon:only-child,
.x_button:nth-last-of-type(1) {
    margin: 0em;
}

.ui_video_element .ui_video_element_bg {
    box-shadow: 0 0px 0px 0px rgb(143 170 175 / 0%);
}

.ui_video_element:hover .ui_video_element_bg {
    /* box-shadow: 0 3px 10px -2px rgb(143 170 175 / 30%); */
    /* background-color: white; */
    /* margin: 0; */
}

.ui_video_element.active .ui_video_element_bg {
    opacity: 1;
    /*    box-shadow: 0 3px 20px -2px rgb(143 170 175 / 50%);
  background-color: white;
  margin: -1em 0 1em 0;*/
}

.ui_video_element .ui_video_element_bg {
    /*background-color: transparent;*/
    opacity: 0;
}

.ui_video_element.active:hover .ui_video_element_bg {
    /*background-color: transparent;*/
}

.ui_video_element:hover .ui_video_element_bg {
    /* background-color: var(--main-accent-color-alpha); */
}

.react-datetime-picker__wrapper {
    border: none !important;
    /* padding: 0.5em 0.4em !important; */
    border-radius: 0.2em !important;
    background: #fcfcfc !important;
    height: 44px;
}

.ui_tabs__menu_item:last-child {
    margin-right: 0;
}

.p_player_comment0 .p_player_comment_glow {
    opacity: 0;
}

.p_player_comment0:hover .p_player_comment_glow {
    opacity: 0.6;
    transform: scale(1, 1);
}

.scrollbar-container {
    height: auto !important;
    -webkit-overflow-scrolling: touch;
}

.x_project_info_line:last-child {
    border: none;
    margin: none;
    padding: none;
}

.noselect {
    pointer-events: none;
}

.swiper-slide {
    /* opacity: 0.3; */
}

.swiper-slide-active {
    opacity: 1;
}

.swiper-slide-active .p_comment_time_frame--slider {
    /* box-shadow: 0 0 0px 2px #06a19a; */
}

.vjs-playback-rate-value {
    z-index: 0 !important;
}

.m0_collapsed .x_logo_text,
.m0_collapsed .l_c_menu_label,
.m0_collapsed .l_c_menu_info_h,
.m0_collapsed .l_c_menu_info_h,
.m0_collapsed .l_c_menu_item_right,
.m0_collapsed .l_c_menu_section--todo {
    display: none;
}

.m0_collapsed .x_logo_img {
    margin: 0;
}

.m0_collapsed .l_c_menu_item {
    margin-left: 0em;
}

.m0_collapsed .l_c_menu_title {
    opacity: 0;
}

.l_c_menu_item.active .l_c_menu_item_num {
    background-color: transparent;
    color: currentColor;
}

.l_c_menu_add {
    opacity: 0;
}

.l_c_menu_section:hover .l_c_menu_add {
    opacity: 1;
}

.x_cover_content:hover .x_button--hover {
    opacity: 1;
}


/*—Info-*/

@media (max-width: 1620px) {
    .l_content_info--1440 {
        /* display: none; */
    }
}

.x_meta_anchorlink:hover .x_meta_hover {
    opacity: 1;
}

.l_pr_columns.l_pr_columns--table:nth-child(odd) {
    background-color: #d8d8d842;
}

.l_pr_col {
    padding-left: 1em;
    padding-right: 1em;
}