.video-js {
  --vjs-primary: #06a19a; /* rgb(6, 161, 154) */
  --vjs-secondary: #42686d; /* rgb(66, 104, 109) */
}

.vjs-loading-spinner {
  border: 6px dashed var(--vjs-primary);
}

.vjs-menu li {
  text-transform: none !important;
}

.vjs-menu-button .vjs-menu-content {
  max-height: 20em !important;
}

.video-js .vjs-big-play-button {
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 7em;
  border-radius: 50%;
  color: var(--vjs-secondary);
  text-shadow: 0 2px 0 #17262c;
  transition: 0.2s ease-in-out !important;
}

.vjs-big-play-button:hover {
  color: var(--vjs-primary);
}
