.vjs-time-control {
  height: 2em;
  color: var(--vjs-secondary) !important;
  transition: 0.3s ease-in-out !important;
}

.video-js .vjs-current-time {
  display: block !important;
}

.video-js .vjs-time-control {
  display: block !important;
  padding-left: 0;
  padding-right: 0;
  min-width: 1em !important;
}
