.vjs-playback-rate .vjs-menu {
  width: 7em;
  bottom: 0.7em;
  left: -1em;
  overflow: visible !important;
  font-size: 0.8em;
  font-weight: normal;
  color: var(--vjs-primary);
  z-index: 1 !important;
}

.vjs-playback-rate .vjs-menu-content {
  max-height: none !important;
}

.vjs-playback-rate .vjs-menu-item {
  border-bottom: 1px solid black;
}

.vjs-icon-speed .vjs-icon-placeholder:before {
  font-family: "Material Icons";
  content: "\e9e4";
}

.vjs-playback-rate-value {
  left: 35px !important;
  top: 3px !important;
  z-index: 1;

  padding: 2px 4px;
  width: auto !important;
  height: auto !important;

  background-color: var(--vjs-secondary);
  color: black;
  border-radius: 0.4em;

  line-height: 1.4em !important;
  font-size: 0.5em !important;
  text-transform: uppercase;
  font-weight: bold;
}

/* Show playback-rate control on small screens*/
.video-js.vjs-layout-small .vjs-playback-rate,
.video-js.vjs-layout-x-small .vjs-playback-rate,
.video-js.vjs-layout-tiny .vjs-playback-rate {
  display: block;
}

@media (max-width: 768px) {
  .vjs-playback-rate-value {
    left: 25px !important;
  }
}

@media (max-width: 468px) {
  .vjs-playback-rate-value {
    left: 12px !important;
    top: 3px !important;
    padding: 1px !important;
    line-height: 1.2em !important;
  }
}
