.video-js .vjs-play-progress,
.video-js .vjs-volume-level {
  background-color: var(--vjs-primary);
}

.video-js .vjs-play-progress:before {
  display: none;
}

.video-js .vjs-load-progress {
  background-color: var(--vjs-primary) !important;
  opacity: 1;
}

.video-js .vjs-progress-control {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  top: -0.3em;
}

.video-js .vjs-progress-holder {
  margin: 0;
  height: 100%;
  box-shadow: none !important;
  margin: 0 !important;
}

.video-js .vjs-progress-control .vjs-slider {
  color: var(--vjs-primary);
  background-color: rgba(6, 161, 154, 0.14);
}
