.video-js .vjs-volume-panel .vjs-slider {
  color: var(--vjs-secondary);
  background-color: var(--vjs-secondary);
}

.vjs-volume-control .vjs-slider-horizontal,
.vjs-volume-control .vjs-slider-horizontal .vjs-volume-level {
  height: 3px;
}

.video-js .vjs-volume-level {
  font-size: 12px;
}
