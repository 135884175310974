.v_speed:hover .v_speed0 {
    max-width: 200px;
}

.v_volume:hover .v_volume_slider {
    width: 4em;
}

.v_speed .v_speed_sm {
    opacity: 1;
}

.v_speed:hover .v_speed_sm {
    opacity: 0;
}