.vjs-control-bar {
  background-color: transparent !important;
  font-size: 1.5em;
}

.vjs-control {
  height: 2em;
  color: var(--vjs-secondary) !important;
  transition: 0.3s ease-in-out !important;
}

.vjs-control:focus {
  text-shadow: none !important;
}

/* Selected option of a menu */
.vjs-selected {
  background-color: var(--vjs-secondary) !important;
  color: var(--vjs-primary) !important;
}

/* Hide closed captions control and text tracks */
.vjs-subs-caps-button,
.vjs-texttrack-settings {
  display: none !important;
}

.video-js .vjs-custom-control-spacer {
  display: flex;
  flex: 1 1 auto;
}

@media (min-width: 768px) {
  /* Change color of icons on hover */
  .vjs-control-bar:hover .vjs-play-control,
  .vjs-control-bar:hover .vjs-playback-rate,
  .vjs-control-bar:hover .vjs-picture-in-picture-control,
  .vjs-control-bar:hover .vjs-quality-selector,
  .vjs-control-bar:hover .vjs-fullscreen-control,
  .vjs-control-bar:hover .vjs-time-control,
  .vjs-control-bar:hover .vjs-mute-control {
    color: var(--vjs-primary) !important;
    transition: 0.3s ease-in-out !important;
  }

  /* Change background-color of icons icons on hover */
  .vjs-play-control:hover,
  .vjs-playback-rate:hover,
  .vjs-picture-in-picture-control:hover,
  .vjs-quality-selector:hover,
  .vjs-fullscreen-control:hover,
  .vjs-mute-control:hover {
    background-color: rgba(6, 161, 154, 0.2);
    transition: 0.3s ease-in-out !important;
  }
}

@media (max-width: 768px) {
  .vjs-control {
    color: var(--vjs-primary) !important;
  }

  .vjs-control-bar {
    font-size: 1em;
  }

  .video-js .vjs-big-play-button {
    font-size: 7em;
  }
}

@media (max-width: 468px) {
  .vjs-control-bar {
    font-size: 0.5em;
  }
}
